import { useCookies } from 'react-cookie';
import { AnimatePresence, m } from 'framer-motion';
import { easeInOutCubic } from '@/utils/easings';
import { useEffect, useState } from 'react';
import Button from '@/components/shared/Button';
import Link from '@/components/shared/Link';

const AGREEMENT_COOKIE_NAME = 'COOKIES_USAGE_ACCEPTED';

const CookieBanner = () => {
    const [{ COOKIES_USAGE_ACCEPTED }, setCookies] = useCookies([AGREEMENT_COOKIE_NAME]);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(COOKIES_USAGE_ACCEPTED !== true);
    }, [COOKIES_USAGE_ACCEPTED]);

    return (
        <AnimatePresence>
            {visible && (
                <m.div
                    initial={{
                        y: 20,
                        opacity: 0,
                        pointerEvents: 'none',
                    }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        pointerEvents: 'auto',
                    }}
                    exit={{
                        opacity: 0,
                        y: -20,
                        pointerEvents: 'none',
                    }}
                    transition={{
                        duration: 0.6,
                        ease: easeInOutCubic,
                    }}
                    className="cookie-banner"
                >
                    <div className="cookie-banner__text text-l">
                        Мы используем{' '}
                        <Link href="/cookie" className="cookie-link" target="_blank" rel="noreferrer">
                            cookie
                        </Link>{' '}
                        для улучшения вашего пользовательского опыта.
                    </div>
                    <Button
                        geometryVariant="mustache"
                        onClick={() => {
                            setCookies(AGREEMENT_COOKIE_NAME, true);
                        }}
                    >
                        Принять
                    </Button>
                </m.div>
            )}
        </AnimatePresence>
    );
};

export default CookieBanner;
